<template>
    <div class="pt-32 flex flex-col items-center">
        <div class="w-full md:w-2/3 lg:w-2/3">
            <van-cell-group class="shadow-md py-4" title="交易记录" inset>
                <transaction-list :transactions="transactions" @click-item="clickTrans" :lang-code="langCode" />
            </van-cell-group>
        </div>
        <van-action-sheet v-model:show="showActions" :actions="actions" @select="selectAction" />
        <transaction-dialog ref="tranDialogRef" :is-Manager="false" :lang-code="langCode" />
    </div>
</template>

<script>
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    CellGroup,
    Toast,
    ActionSheet
} from "vant";
import TransactionList from "@/components/list/TransactionList.vue";
import TransactionDialog from "@/components/dialog/TransactionDetails.vue";
import GetSession from "@/asyncs/GetSession.js";
import ListTransactions from "@/asyncs/ListTransactions.js";

export default {
    components: {
        [CellGroup.name]: CellGroup,
        [ActionSheet.name]: ActionSheet,
        TransactionList,
        TransactionDialog
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        store.commit("setPageTitle", "个人支付记录");
        const router = useRouter();
        const route = useRoute();
        const transactions = ref(null);
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                transactions.value = await ListTransactions(store.state.user.userId);
            });
        });
        const showActions = ref(false);
        const tranDialogRef = ref(null);
        let pickedTransaction = null;
        const actions = [{
            id: 1,
            name: "查看详细信息"
        },
        {
            id: 2,
            name: "申请退款",
            color: "#ee0a24"
        }
        ];
        const selectAction = function (e) {
            showActions.value = false;
            if (e.id === 1) {
                tranDialogRef.value.showDialog(pickedTransaction);
            } else if (e.id === 2) {
                Toast.fail("抱歉，此功能暂时不可使用")
            }
        };
        const clickTrans = function (item) {
            showActions.value = true;
            pickedTransaction = item;
        };
        return {
            langCode: computed(() => store.state.lang),
            transactions,
            showActions,
            actions,
            selectAction,
            tranDialogRef,
            clickTrans
        };
    }
}
</script>
